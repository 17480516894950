<template>
  <v-container>
    <overlay-loader overlay :loading="loadingPage"></overlay-loader>
    <div v-for="company in companies">
      <companies-list-item :company="company" @updateBalance="updateBalance" @updateTax="updateTax"/>
    </div>
  </v-container>
</template>

<script>

import axios from "axios";
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import CompaniesListItem from "@/components/CompaniesPage/CompaniesListItem.vue";
export default {
  name: "Companies",
  components: {CompaniesListItem, OverlayLoader},
  data(){
    return{
      companies:[],
      loadingPage: false,
    }
  },
  mounted() {
    this.fetchCompanies()
  },
  methods:{
    updateBalance(updatedCompanyId, value) {
      this.companies = this.companies.map(company => {
        if (company.id === updatedCompanyId) {
          company.ballance = value
          return company
        }
        return company
      })
    },
    updateTax(updatedCompanyId, value) {
      this.companies = this.companies.map(company => {
        if (company.id === updatedCompanyId) {
          company.tax = value
          return company
        }
        return company
      })
    },
    async fetchCompanies() {
      this.loadingPage = true;
      let url = this.$enums.Endpoints.Data.Companies
      try {
        let resp = await this.$axios.get(url)
        this.companies = resp.data
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingPage = false;
      }
    }
  }

}
</script>


<style scoped>

</style>