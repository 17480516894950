export function downloadFile(
  byteArray,
  { openInNewTab = true, type = "application/pdf", name = 'file.pdf' } = {}
)
{
  let blob = new Blob([byteArray], { type: type });

  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  let url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = name;
  a.click();

  if (openInNewTab) window.open(url, "_blank");

  window.URL.revokeObjectURL(url);
}
